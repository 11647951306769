<template>
    <div class="rentaldeskthis mb-5">
        <modal :show.sync="newModalReservation" :showClose="false" v-if="newModalReservation" gradient="secondary" modal-classes="more-info modal-secondary">
            <template slot="header">
                <h4>Nový prenájom</h4>
            </template>
            <template  v-if="newExtra">
                     <div  class="row new-reservation">
                        <a-input label="Meno" input-classes="form-control-alternative form-control" v-model="newExtra.delivery_first_name"/>
                        <a-input label="Priezvisko" input-classes="form-control-alternative form-control" v-model="newExtra.delivery_last_name"/>
                        <a-input label="Email" type="email" input-classes="form-control-alternative form-control" v-model="newExtra.primary_email"/>
                        <a-input label="Telefón" input-classes="form-control-alternative form-control" v-model="newExtra.delivery_phone"/>
<div class="form-group">
                        <div class="w-50" style="float:left">
                            <label class="w-100">Termín od: </label> <a-date-picker  v-model="newExtra.daterange"  :locale="locale"  @change="getRental" /></div>

                        <div class="w-50" style="float:left"><label>Počet dní:</label>
                       <a-select class="w-100" v-model="newExtra.days" @change="getRental" :options="daystoorder"/> 
                        </div>
                        </div>
                        <div class="form-group"  v-if="bikes"><label>Bike:</label>
                       
                            <a-select class="w-100"  v-model="newExtra.product">
                                <a-select-opt-group :key="key" v-for="(model,key) in bikes">
                                    <span slot="label" class="font-weight-bolder" style="font-weight:bold; color:#000;font-size:13px;padding-top:20px;"><i class="fad fa-biking pr-2" />{{bikeModels[key]}}</span>
                                    <a-select-option :key="ini" :value="bike.value" v-for="(bike,ini) in model">
                                        {{bike.label}}
                                    </a-select-option>
                                </a-select-opt-group>
                            </a-select>
                        </div>

                        <div class="col-xl-12 p-2">
                            <label class="form-control-label">Poznámka</label>
                            <a-textarea alternative="" input-classes="form-control-alternative"  v-model="newExtra.message"/>
                        </div>
                         <div class="form-group"><label>Servisný deň</label>
                         <span class="form-control" style="border:0;"><a-switch v-model="newExtra.serviceday" default-checked /></span></div>
                    </div>
                    <template slot="footer"  style="padding-top:0;">
                        <a-button  type="white" class="ml-auto" @click="newModalReservation = false; newExtra = {};">Zatvoriť
                        </a-button>
                        <a-button type="primary" @click="addReservation"> Vytvoriť</a-button>

                    </template>
                </template>
        </modal>
        <div>
            <div   class="pb-6 pb-300 diagonal pt-2 pt-md-7">
                <div class="py-4 mt--4 mb--3">
                    <h6 class="h2 text-white d-inline-block mb-0">Plachta prenájmov</h6>
                    <div class="text-right" style="float:right">
                             <button  @click="openReservationModal" class="btn btn-primary">Nový prenájom <i class="fas fa-plus-circle" style="margin-left:5px;margin-right:-5px;"></i></button>
                         </div>
                </div>
            </div>
            <modal :show.sync="newModal" :showClose="false" v-if="newModal" gradient="secondary" modal-classes="more-info modal-secondary">
                <template slot="header">
                    <h4>Nová rezervácia</h4>
                </template>
                <template>
                    <div class="texttab">
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-12">

                                    <a-input label="Meno" input-classes="form-control-alternative form-control-commpact" v-model="newReservation.delivery_first_name"/>
                                    <a-input label="Priezvisko" input-classes="form-control-alternative form-control-commpact" v-model="newReservation.delivery_last_name"/>
                                    <a-input label="Email" type="email" input-classes="form-control-alternative form-control-commpact" v-model="newReservation.primary_email"/>
                                    <a-input label="Telefón" input-classes="form-control-alternative form-control-commpact" v-model="newReservation.delivery_phone"/>

                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template slot="footer" class="footer-dark">
                    <a-button type="link" class="ml-auto" @click="newModal = false;">Zavrieť</a-button>
                    <a-popconfirm placement="topRight"
                                  @confirm="reservationDelete(newReservation.prodid)"
                                  @cancel="cancel"
                                  okText="Áno"
                                  class="btn btn-outline-dark"
                                  cancelText="Nie">
                        <template slot="title">
                            <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                            Položka bude trvalo odstránená z vašej stránky.
                        </template>Odstrániť rezerváciu</a-popconfirm>
                    <a-button type="primary" @click="addReservation">Uložiť</a-button>
                </template>
            </modal>
            <modal :show.sync="responseEditModal" :showClose="false" v-if="responseEditModal" gradient="secondary" modal-classes="more-info edit-modal modal-secondary">
                <template slot="header">
                    <h4>editácia rezervácie č.: {{sres.data.order_id}}<br>{{sres.room.title}}</h4>
                    <div class="subtext" v-if="isValidDate(sres.proddata.modified)">Posledná zmena: {{formatDateNT(sres.proddata.modified)}}</div>
                </template>
                <a-tabs default-active-key="reservation" tab-position="left" @prevClick="callback" @nextClick="callback">
                    <a-tab-pane key="reservation" class="texttab" tab="Rezervácia">
                        <span v-if="isValidDate(sres.proddata.created)"> <label>Vytvorené: </label> <span class="text-info-order">{{formatDateNT(sres.proddata.created)}}</span></span><template>
                        <div class="form-group"><label>Termín: </label> <a-range-picker class="form-control-commpact"  v-model="sres.daterange"  :locale="locale"  @change="onChange" /></div>
                        <div class="form-group"><label>Servisný deň</label>  <span class="form-control-commpact" style="border:0;"><a-switch v-model="sres.proddata.serviceday" /></span></div>
                        <div class="form-group"  v-if="bikes"><label>Bike:</label>
                            <span class="form-control-commpact" style="border:0;">   
                                <a-select class="w-100" :style="wrongModel ? 'color:red' : ''" @change="changeBike" :default-value="parseInt(sres.proddata.product)" >
                                <a-select-opt-group :key="key" v-for="(model,key) in bikes">
                                    <span slot="label" class="font-weight-bolder" style="font-weight:bold; color:#000;font-size:13px;padding-top:20px;"><i class="fad fa-biking pr-2" />{{bikeModels[key]}}</span>
                                    <a-select-option :value="parseInt(bike.value)" :key="bike.value + 'b'" v-for="bike in model">
                                  {{bike.label}}
                                    </a-select-option>
                                </a-select-opt-group>
                            </a-select></span>
                        </div> 
                        <div class="form-group"  v-if="statuses.length"><label>Status:</label>
                            <span class="form-control-commpact" style="border:0;">   
                                <a-select class="w-100" :style="wrongModel ? 'color:red' : ''" @change="changeStatus" :default-value="sres.proddata.status ? sres.proddata.status : 'pending'" >
                                    <a-select-option :value="status.value" :key="status.value" v-for="status in statuses">
                                  {{status.label}}
                                    </a-select-option>                                
                            </a-select></span>
                        </div>
                    </template>
                    </a-tab-pane>
                    <a-tab-pane key="order" class="texttab orderedit" tab="Objednávka">
                        <a-input  label="Meno" input-classes="form-control-alternative form-control-commpact" v-model="sres.data.delivery_first_name"/>
                        <a-input  label="Priezvisko" input-classes="form-control-alternative form-control-commpact" v-model="sres.data.delivery_last_name"/>
                        <a-input  label="Email" type="email" input-classes="form-control-alternative form-control-commpact" v-model="sres.data.primary_email"/>
                        <a-input  label="Telefón" input-classes="form-control-alternative form-control-commpact" v-model="sres.data.delivery_phone"/>
                        <a-input  label="Ulica" input-classes="form-control-alternative form-control-commpact" v-model="sres.data.delivery_street1"/>
                        <a-input  label="Mesto" input-classes="form-control-alternative form-control-commpact" v-model="sres.data.delivery_city"/>
                        <a-input  label="PSČ" input-classes="form-control-alternative form-control-commpact" v-model="sres.data.delivery_postal_code"/>
                        <a-input  label="Štát" input-classes="form-control-alternative form-control-commpact" v-model="sres.data.delivery_state"/>
                        <hr>
                        <a-input  label="Firma" input-classes="form-control-alternative form-control-commpact" v-model="sres.data.billing_company"/>
                        <a-input  label="IČO" input-classes="form-control-alternative form-control-commpact" v-model="sres.data.data.billing_ico"/>
                        <a-input  label="DIČ" input-classes="form-control-alternative form-control-commpact" v-model="sres.data.data.billing_dic"/>
                        <a-input  label="IČ DPH" input-classes="form-control-alternative form-control-commpact" v-model="sres.data.data.billing_icdph"/>
                        <hr>
                        <a-input  label="Doklad č.1" input-classes="form-control-alternative form-control-commpact" v-model="sres.data.data.id"/>
                        <a-input  label="Doklad č.2" input-classes="form-control-alternative form-control-commpact" v-model="sres.data.data.id2"/>

                    </a-tab-pane>
                    <a-tab-pane key="services4" tab="Produkty">
                        <h4>Produkty v objednávke</h4>
                        <div v-for="product in sres.data.products" :key="'edd' + product.title+product.nid" >
                       <span :class="'service-product ' + (product.data.length === 0 ? 'crossed' : '')">
                           {{product.title}} <b>{{Number(product.price).toLocaleString()}} €</b></span>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="notes" class="texttab" tab="Poznámky">
                          <div class="col-xl-12 p-2">
                            <label class="form-control-label">Poznámka</label>
                            <a-textarea alternative="" input-classes="form-control-alternative"  v-model="sres.data.newnote"/>
                        </div>
                            <a-timeline style="margin-top:25px;">

<span :key="oind" v-for="(item,oind) in sres.data.notes">
                                <div v-if="item.system === '0' || !item.system">
                                    <a-timeline-item :key="'note-order-' + oind"  :color="item.color">

                                  <i slot="dot" :class="item.icon" style="font-size: 16px;" v-if="item.icon" />
                                    <div class="note-content">
                                        <small>{{formatDate(item.created)}}</small>
                                        <h4>{{item.title}}</h4>  <span v-html="item.message"></span></div>

                                </a-timeline-item>
                                </div>
    </span>
                            </a-timeline>

                    </a-tab-pane>
                </a-tabs>
                <template slot="footer" class="footer-dark">
                    <a-button type="link" class="ml-auto" @click="responseEditModal = false">Zavrieť</a-button>
                    <a-button type="primary" :disabled="wrongModel" @click="updateReservation">Uložiť</a-button>
                </template>
            </modal>
            <modal :show.sync="responseModal" :showClose="false" v-if="responseModal" gradient="secondary" modal-classes="more-info detail-modal modal-secondary">
                <template slot="header">
                    <h4>detail rezervácie</h4>
                    <div class="subtext" ><div v-if="isValidDate(sres.proddata.modified)">Posledná zmena: {{formatDateNT(sres.proddata.modified)}}</div></div>
                </template>
                <a-tabs
                        default-active-key="info"
                        tab-position="left"
                        @prevClick="callback"
                        @nextClick="callback"
                >
                    <a-tab-pane key="info" class="texttab" tab="Informácie">

                        <label>Termín: </label> {{formatDateN(sres.datefrom)}} - {{formatDateN(sres.dateto)}}<br>
                        <label>Model </label> {{sres.proddata.title}}<br>
                        <label>Bicykel: </label> {{sres.room.title}}<br>
                        <label>Status: </label>
                        <div class="data-info">
                            <a-tag v-if="sres.status === 'pending'" color="">Objednávka</a-tag>
                            <a-tag v-if="sres.status === 'payment_recieved'"  color="orange">Prijatá platba</a-tag>
                            <a-tag v-if="sres.status === 'storno'"  color="red">Stornovaná</a-tag>
                            <a-tag v-if="sres.status === 'abandoned'"  color="red">Opustená</a-tag>
                            <a-tag v-if="sres.status === 'completed'"  color="green">Kompletná</a-tag>
                        </div>
                        <br>
                        <label>Spôsob platby:</label>
                        <div class="data-info">
                            <a-tag v-if="sres.data.payment_method === 'cod'" color="">Platba na mieste</a-tag>
                            <a-tag v-if="sres.data.payment_method === 'desk'" color="">Zadané online</a-tag>
                            <a-tag v-if="sres.data.payment_method === 'payout'"  color="green">Karta</a-tag>
                            <a-tag v-if="sres.data.payment_method === 'bank'"  color="red">Bankový prevod</a-tag>
                            <a-tag color="orange"  v-if="sres.data.payment_method === 'bank'"  >VS: {{sres.data.vs}}</a-tag>
                        </div>
                        <br>

                        {{sres.first_name}} {{sres.last_name}}
                        <span v-if="sres.proddata.room_name"> <label>Izba na meno: </label> {{sres.proddata.room_name}} <br></span>
                        <label>Kontakt: </label> {{sres.data.primary_email}} {{sres.data.phone}}<br>
                        <label>Počet dní: </label> {{sres.nights}} <span v-if="sres.proddata.serviceday != false"> (zahrnutý servisný deň)</span><br>
                        <span v-if="sres.proddata.created"> <label>Vytvorené: </label> {{formatDateNT(sres.proddata.created)}}<br><br></span>
                        <span v-if="sres.shipping"> <label>Doručenie </label> <br></span>
                        <span v-if="sres.shipping && sres.shipping.hotel != 0"> <label>Miesto: </label>{{sres.shipping.hotel}}<br></span>
                        <span v-if="sres.shipping && sres.shipping.shop != 0"> <label>Požičovňa: </label>{{sres.shipping.shop}}<br></span>
                        <span v-if="sres.shipping"> <label>Druh: </label><span v-if="sres.shipping.shipment === 'deliver'">Dovoz na hotel</span><span v-else>Osobný odber</span><br></span>
                        <span v-if="sres.shipping"> <label>Cena: </label>{{sres.shipping.price}} €<br></span>
                    </a-tab-pane>
                    <a-tab-pane key="order2" class="ordertab" tab="Objednávka">
                        <div v-for="(orderdata,ind) in sres.data.data" :key="'orbef'+ind">
                       <span v-if="ind !== 'reservations' && ind !== 'data' && orderdata && ind !== 'rep' && ind !== 'order_message' && ind !== 'products'">
                           <label>{{ind}}: </label>{{orderdata}}</span>
                            <div class="note" v-if="ind === 'order_message'"><label>Poznámka:</label>{{orderdata}}</div>
                        </div>

                    </a-tab-pane>
                    <a-tab-pane key="notesin" tab="Poznámky">

                        <a-timeline style="margin-top:25px;">

<span :key="oind" v-for="(item,oind) in sres.data.notes">
                                <div v-if="item.system === '0' || !item.system">
                                    <a-timeline-item :key="'note-order-' + oind"  :color="item.color">

                                  <i slot="dot" :class="item.icon" style="font-size: 16px;" v-if="item.icon" />
                                    <div class="note-content">
                                        <small>{{formatDate(item.created)}}</small>
                                        <h4>{{item.title}}</h4>  <span v-html="item.message"></span></div>

                                </a-timeline-item>
                                </div>
    </span>
                        </a-timeline>

                    </a-tab-pane>
                    <a-tab-pane key="services3" tab="Produkty">
                        <h4>Produkty v objednávke</h4>
                        <div v-for="product in sres.data.products" :key="'show' + product.title+product.nid" >
                       <span :class="'service-product ' + (product.data.length === 0 ? 'crossed' : '')">
                           {{product.title}} <b>{{Number(product.price).toLocaleString()}} €</b></span>
                        </div>
                    </a-tab-pane>
                </a-tabs>

                <template slot="footer" class="footer-dark">
                    <a-button type="primary" @click="responseModal = false">OK</a-button>
                </template>

            </modal>
            <div class="container-fluid" style="margin-top:-270px">
                <div class="row">
                    <div class="col-xl-12 mb-5 mb-xl-0 pr-0">
                        <a-card type="white" header-classes="bg-blueish" body-classes="p-0 overflow-hidden ">
                            <div class="header row align-items-center">
                                <div class="col top-panel">
                                    <a-button type="secondary" @click="subAMonth" ><i class="fas fa-chevron-double-left"></i></a-button>
                                    <a-button type="secondary" @click="subAWeek"><i class="fas fa-chevron-left"></i></a-button>
                                    <a-range-picker v-model="dateranger" @change="changeDate" :locale="locale" separator="-" :format="dateFormat"><template slot="suffixIcon"><i class="fal fa-calendar-alt"></i></template></a-range-picker>
                                    <a-button type="secondary" @click="addAWeek" ><i class="fas fa-chevron-right"></i></a-button>
                                    <a-button type="secondary" @click="addAMonth"><i class="fas fa-chevron-double-right"></i></a-button>
                                </div>
                                <div class="col">
                                    <ul class="nav nav-pills justify-content-end">
                                        <li class="nav-item mr-2 mr-md-0">
                                            <a-button type="secondary" @click="scrollleft" ><i class="fas fa-chevron-left"></i></a-button>
                                            <a-button type="secondary" @click="scrollright" style="margin-left:5px;"><i class="fas fa-chevron-right"></i></a-button>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <template>
                                <div class="ttxt">
                                    <table class="w-100" >
                                        <thead class="col-lg-4 col-md-4">
                                        <tr>
                                            <th class="room-label">Produkt</th>
                                        </tr>
                                        </thead>
                                        <tbody class="leftsider"> 
                                              <tr v-bind:class="['r_'+room.model+'_'+room.id, 'room']" v-for="(room, index) in arooms" :key="'ji'+room.id+index+room.id">
                                            <th v-if="rt[room.model]" :title="rt[room.model].title">
                                            <img :src="IMG_URL + '102:68:1' + JSON.parse(room.image).uri" class="product-image">
                                                <span class="product-title" >{{room.title}}<br> <small>{{room.serial}}</small></span>
                                            </th>
                                            <th v-else class="other">
                                                <img :src="IMG_URL + '102:68:1' + JSON.parse(room.image).uri" class="product-image">
                                                <span class="product-title" >{{room.title}}</span></th>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="tlxt auto-x-overflow" id="desk">

                                    <table class="tableFixHead">
                                        <thead class="" :style="`top:${scrollfromtop}px`">
                                        <tr>
                                            <th :key="inde" v-for="(day,inde) in periode" :class="'dayday d_' +day.value "><div class="datename">{{moment(day.raw).format("ddd")}}</div><div class="dayday">{{moment(day.raw).format("DD")}}</div></th>
                                        </tr>
                                        </thead>
                                        <tbody class="leftsider">
                                        <tr v-bind:class="['r_'+room.id.replace('+','__')+'_'+room.id, 'froom']" v-for="(room, index) in arooms" :key="'ji'+room.id+index+room.id">
                                            <td :key="indexx" v-for="(day,indexx) in periode" :class="'blank d_' +day.value "></td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <table class="reservationshot" >
                                        <tr v-bind:class="['r_' + room.model + '_' + room.id,'room']" v-for="(room, index) in arooms" :key="room.id+index+room.model">
                                          <td class="hidden hidden-left" v-infocus="'showElement'">
                                                <div v-for="(reservation,ind) in reservations[room.model+'_' + room.id]" :key="'ree'+ind"
                                                     v-bind:class="['status_'+reservation.status,'r_'+room.model+'_' + reservation.aroom, 'reservation', 'serviceday_' + reservation.serviceday]"
                                                     :style="'width:' + (((reservation.nights-1) * 52) + 52) + 'px;left:'+ ((reservation.leftposition * 52)+39) + 'px'"
                                                     @click="openModal(reservation,room)">
                                                    <b v-bind:class="['status_'+reservation.status]"></b>
                                                    <a-dropdown :trigger="['contextmenu']">
                                                        <a-tooltip placement="bottom">
                                                            <template slot="title">
                                                                <span v-if="isValidDate(reservation.proddata.created)"> <label>Vytvorené: </label> {{formatDateNT(reservation.proddata.created)}}</span>
                                                                <div v-if="reservation.data.order_id"><label>Číslo objednávky: </label> {{reservation.data.order_id}}</div>
                                                                <div v-if="reservation.guests[0].first_name">Objednal: {{reservation.guests[0].first_name}} {{reservation.guests[0].last_name}}</div>
                                                                <div><label>Termín: </label> {{formatDateN(reservation.datefrom)}} - {{formatDateN(reservation.dateto)}}</div>
                                                                <div v-if="reservation.data.data.pickuptime"><label>Čas vyzdvihnutia: </label> {{reservation.data.data.pickuptime}}</div>
                                                                <div v-if="reservation.data.data.deliverytime"><label>Čas odovzdania: </label> {{reservation.data.data.deliverytime}}</div>
                                                                <div v-if="reservation.data.data.order_message"><label>Poznámka: </label> {{reservation.data.data.order_message}}</div>
                                                                <div v-if="!reservation.closed"> <label>Počet dní: </label> {{reservation.nights}}</div>
                                                                <div v-if="reservation.closed">
                                                                    <label>Zatvorené: </label>
                                                                    <div class="gnam"><span v-for="gn in reservation.closed" :key="gn">{{gn}}<br></span></div></div>
                                                            </template>
                                                            <span v-if="reservation.guests"> {{reservation.guests[0].first_name}} {{reservation.guests[0].last_name}}
                                                                <span v-if="reservation.status === 'incart'"  style="margin-top:-3px;margin-left:-12px; opacity:.5">V košíku</span>
 <b v-if="reservation.shipping  && reservation.shipping.hotel != '0'" style="padding-top:25px;padding-left:12px; opacity:.7"> {{reservation.shipping.hotel}}</b> 
<b v-if="reservation.proddata.sourceofbuy" style="padding-top:25px;padding-left:12px; opacity:.5"> {{ reservation.proddata.sourceofbuy }}</b>     
</span>
                                                        </a-tooltip>
                                                        <a-menu slot="overlay"  v-if="reservation.status !== 'closed'">
                                                            <a-menu-item key="1"  @click="openModal(reservation,room)">Zobraziť</a-menu-item>
                                                            <a-menu-item key="2" @click="openEditModal(reservation,room)">Editovať</a-menu-item>
                                                            <a-menu-item key="5" @click="openOrder(reservation)" v-if="reservation.data.order_id">Objednávka</a-menu-item>
                                                            <a-menu-item class="nopadding" key="4">
                                                                <a-popconfirm placement="bottomRight"
                                                                              @confirm="reservationDelete(reservation.proddata.id)"
                                                                              @cancel="cancel"
                                                                              okText="Áno"
                                                                              cancelText="Nie">
                                                                    <template slot="title">
                                                                        <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                                                        Položka bude trvalo odstránená z vašej stránky.
                                                                    </template>Zmazať</a-popconfirm>
                                                            </a-menu-item>
                                                        </a-menu>
                                                    </a-dropdown>
                                                </div>

                                            </td>
                                        </tr>
                                    </table>    </div>
                            </template>

                        </a-card>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    // Charts
    import {dataService} from "../_services";
    import {Button} from "ant-design-vue"
    import DatePicker from "ant-design-vue/es/date-picker";

    import moment from 'moment';
    import locale from 'ant-design-vue/es/date-picker/locale/sk_SK';
    import {Tooltip,Tabs,Timeline,Popconfirm,Dropdown,Select, Switch, Tag} from 'ant-design-vue';
    import {IMG_URL} from "../api";
    import 'moment/locale/sk';

    import ATextarea from "ant-design-vue/es/input/TextArea";


    var getDateArray = function (start, end) {
        var arr = [];
        var dt = new Date(start);
        while (dt <= end) {
            var d = new Date(dt);
            var datestring = d.getDate() + "." + (d.getMonth() + 1);
            var weekDayName = moment(d).weekday();

            if (moment(d).isSame(moment(), 'day'))
                weekDayName = weekDayName + ' isToday';

            arr.push({display: datestring, raw: d, value: weekDayName});
            dt.setDate(dt.getDate() + 1);
        }
        return arr;
    };


    export default {
        components: {
            "a-button": Button, 
            "a-switch": Switch,
            "a-date-picker": DatePicker,
            "a-range-picker": DatePicker.RangePicker,
            "a-tooltip": Tooltip,
            "a-tabs": Tabs, 
            ATextarea, 
            "a-tab-pane":Tabs.TabPane,
            "a-popconfirm": Popconfirm,
            "a-timeline": Timeline,
            "a-timeline-item": Timeline.Item,
            "a-select":Select,
            "a-select-opt-group":Select.OptGroup,
            "a-select-option":Select.Option,
            "a-tag": Tag,
            'a-dropdown':Dropdown,
        },
        data() {
            return {
                message: "",
                locale,
                dateranger: "",
                responseModal: false,
                responseEditModal: false,
                lastState: 0,
                provinceData: '',
                statuses: [],
                IMG_URL,
                periode: "",
                bikes: {},
                colorsto: JSON.parse(localStorage.colors),
                rdateFormat: 'YYYY/MM/DD',
                showassigned: false,
                changedroom:'',
                newReservation: {},
                datefrom:"",
                dateto:"",
                newExtra: {'serviceday':true,product: '',days:1},
                newModalReservation: false,
                   daystoorder: [
                    {"value" : 1 ,"label" : '1 deň'},
                    {"value" : 2 ,"label" : '2 dni'},
                    {"value" : 3 ,"label" : '3 dni'},
                    {"value" : 4 ,"label" : '4 dni'},
                    {"value" : 5 ,"label" : '5 dní'},
                    {"value" : 6 ,"label" : '6 dní'},
                    {"value" : 7 ,"label" : '7 dní'},
                    {"value" : 8 ,"label" : '8 dní'},
                    {"value" : 9 ,"label" : '9 dní'},
                    {"value" : 10 ,"label" : '10 dní'},
                    {"value" : 11 ,"label" : '11 dní'},
                    {"value" : 12 ,"label" : '12 dní'},
                ],
                orderstat: [
                    {"value" : 'pending' ,"label" : 'Predbežná rezervácia - dopyt'},
                    {"value" : 'completed' ,"label" : 'Nová rezervácia'},
                    {"value" : 'payment_recieved' ,"label" : 'Nová rezervácia so zálohou'},
                ],
                cities: '',
                rooms: "",
                arooms: "",
                rt: "",
                dateFormat: 'DD.MM.YYYY',
                overflowy:"overflowy",
                loading: true,
                reservationThisMonth: "",
                interval: false,
                wrongModel:false,
                newModal:false,
                newresid:0,  
                models:{},
                selDate:"",
                bikeModels:[],
                selType:false,
                selFroom:false,
                selRoom:false,
                guestsCheckin: {},
                scrollfromtop: -70,
                selFrom: false,
                selTo: false,
                cityData: '', 
                secondCity: '',
                sres: {},
                reservations: [1]
            };
        },
        methods: {
            addAWeek()
            {
                this.dateranger[0].add(1, 'w');
                this.dateranger[1].add(1, 'w');
                this.changeDate(this.dateranger);
            },
            changeBike(e)
            {
                this.sres.data.data.product = e;
            },
            changeStatus(e)
            {
                this.sres.data.data.status = e;
            },
            addAMonth()
            {
                this.dateranger[0].add(1, 'months');
                this.dateranger[1].add(1, 'months');
                this.changeDate(this.dateranger);
            },
            subAWeek()
            {
                this.dateranger[0].subtract(1, 'w');
                this.dateranger[1].subtract(1, 'w');
                this.changeDate(this.dateranger);
            },
            subAMonth()
            {
                this.dateranger[0].subtract(1, 'months');
                this.dateranger[1].subtract(1, 'months');
                this.changeDate(this.dateranger);
            },
            changeDate(e)
            {
                this.dateranger = e;
                this.fetch();
            },
            submitUpdate()
            {

               this.responseModal = false;

            },
            formatDate: function (dt) {
                return moment.unix(dt).format('D.M.YYYY H:mm')

            },
            handleProvinceChange(value) {
                this.cities = this.cityData[value];
                this.secondCity = "";
            },
            cancel() {
            },
            openReservationModal()
            {
                this.chooseTimes = {};
                this.newModalReservation = true;
            },
            moment,
            backEndDateFormat(date) {
                return moment(date._d).format('YYYY-MM-DD');
            },
            toggleForm()
            {
                this.overflowy = (this.overflowy === 'overflowy' ? '' : 'overflowy');
            },
            searchTable(input) {
                var  filter, table, tr, td, cell, i, j;
                filter = input.target.value.toUpperCase();
                table = document.getElementById("myTable");
                tr = table.getElementsByTagName("tr");
                for (i = 1; i < tr.length; i++) {
                    // Hide the row initially.
                    tr[i].style.display = "none";

                    td = tr[i].getElementsByTagName("td");
                    for (j = 0; j < td.length; j++) {
                        cell = tr[i].getElementsByTagName("td")[j];
                        if (cell) {
                            if (cell.innerHTML.toUpperCase().indexOf(filter) > -1) {
                                tr[i].style.display = "";
                                break;
                            }
                        }
                    }
                }
            },
            onChange(date) {
                this.sres.datefrom = this.backEndDateFormat(date[0]);
                this.sres.dateto = this.backEndDateFormat(date[1]);
                this.cher(this.sres,this.sres.room);

            }, 
            scrollright() {
let inputP = document.getElementById('desk').scrollLeft;
                    document.getElementById('desk').scrollLeft += 150;
            if(inputP === document.getElementById('desk').scrollLeft)
            {
                this.dateranger[0].add(1, 'd');
                this.dateranger[1].add(1, 'd');
                this.changeDate(this.dateranger);
            }

            },
            scrollleft() {
                let inputP = document.getElementById('desk').scrollLeft;
                document.getElementById('desk').scrollLeft -= 150;
                if(inputP === document.getElementById('desk').scrollLeft)
                {
                    this.dateranger[0].subtract(1, 'd');
                    this.dateranger[1].subtract(1, 'd');
                    this.changeDate(this.dateranger);
                }
            },
            onChangeDateb(e,gid)
            {

                this.sres.guests[gid].birthdate = e;
            },
            callback( ) {

            },
            isValidDate(value) {
                var dateWrapper = new Date(value);
                return !isNaN(dateWrapper.getDate());
            },
            selectBetween(room,index)
            {

                if(this.selFroom && this.selRoom)
                {
                    [].forEach.call(document.querySelectorAll('.inspace'), function (el) {
                        el.classList.remove('betweenselected');
                        el.classList.remove('andlast');
                    });
                    var r;
                    for(r = (this.selFroom+1); r <= index; r++ )
                    {
                        var e = document.querySelector('.r_'+this.selRoom+'.p_'+r+' .inspace');
                        e.classList.add("betweenselected");
                        if(r === index)
                            e.classList.add("andlast");
                    }
                }
            },
            selectBlank(e,r,event,index)
            {

                var target = event.target;

                if(this.selRoom)
                {
                    if(this.selRoom !== r.id)
                        this.selRoom = false;
                    this.selType = false;
                    this.selFroom = false;
                }

                if(!this.selFrom)
                {  [].forEach.call(document.querySelectorAll('.inspace'), function (el) {
                    el.classList.remove('selected');
                    el.classList.remove('betweenselected');
                    el.classList.remove('andlast');

                });
                    this.selFrom = e.raw;
                    target.classList.add('selected');
                    this.selRoom = r.id;
                    this.selFroom = index;
                }
                else
                { this.selTo = e.raw;

                    target.classList.add('selected');
                }

                if(this.selFrom && this.selTo)
                {
                    //  this.selDate = [moment(this.selFrom ,this.rdateFormat),moment(this.selTo,this.rdateFormat)];
                    this.newReservation = {};
                    dataService.axiosPost("cbr/" + moment(this.selFrom).format('YYYY-M-D') + '.' + moment(this.selTo).format('YYYY-M-D')+'/'+this.selRoom).then(results => {
                        this.newReservation.prodid = results.data.message;

                        this.newModal = true;
                        this.selFrom = false;
                        this.selFroom = false;
                        this.selRoom = false;
                        this.selTo = false;
                        this.fetch();   //   if(rese.order_id) this.$router.push('/order/'+rese.order_id)
                        [].forEach.call(document.querySelectorAll('.inspace'), function (el) {
                            el.classList.remove('selected');
                            el.classList.remove('betweenselected');

                        });   });

                }
            },
            formatDateN: function (dt) {
                return moment(dt, 'YYYY-MM-DD').format('D.M.YYYY')
            },   formatDateNT: function (dt) {
                return moment(dt, 'YYYY-MM-DD H:mm').format('D.M.YYYY H:mm')
            },
            openModal(rese,room)
            {
                rese.room = room;
                this.sres = rese;
                this.responseModal = true;
            },
            getRental: function (e) { 
                var from = this.backEndDateFormat(moment(this.newExtra.daterange['_d'], "DD-MM-YYYY")); 
var to = this.backEndDateFormat(moment(this.newExtra.daterange['_d'], "DD-MM-YYYY").add(this.newExtra.days, 'days'));
              //  let daterange = [moment(rese.datefrom, this.rdateFormat), moment(rese.dateto, this.rdateFormat)];


                dataService.axiosFetch("getrental/" + from + "/" + to).then(results => {
                    this.newExtra.model = "";this.sres.model = "";
                    this.cityData = results;
                    this.bikes = results; 
 

                });  

            },
            cher: function (rese, room) {

                var from = rese.datefrom;
                var to = rese.dateto;
                rese.room = room;
                this.sres = rese;


                this.sres.daterange = [moment(rese.datefrom, this.rdateFormat), moment(rese.dateto, this.rdateFormat)];

                dataService.axiosFetch("getrental/" + from + "/" + to + '/' + this.sres.id).then(results => {
                    this.newExtra.model = "";this.sres.model = "";
                    this.cityData = results;
                    this.bikes = results;  
                    let istsok = false;

for (const [key, value] of Object.entries(this.bikes)) {
key;
value.forEach(bikeobj => {
   
   if(istsok === false)
   {
if(parseInt(bikeobj.value) === parseInt(this.sres.proddata.product))
{istsok = true;}
}
})                  

if(!istsok) 
this.wrongModel = true;
else
this.wrongModel = false;
                }
            });

              this.responseModal = false;
                 this.responseEditModal = true;

            },
            openOrder(rese)
            {
                if(rese.data.order_id) this.$router.push('/rental-order/'+rese.data.order_id)
            },
            openEditModal(rese,room)
            {
                this.cher(rese,room);
            },
            changeRoom(e)
            {
                this.sres.aroom = e;
            },
            addReservation()
            {
                if(this.newExtra.product)
                {

var from = this.newExtra.daterange; 
var to = moment(this.newExtra.daterange['_d'], "DD-MM-YYYY").add(this.newExtra.days, 'days');
this.newExtra.daterange = [];
this.newExtra.daterange[0] = from;
this.newExtra.daterange[1] = to;

                dataService.axiosPost("arent", this.newExtra).then(() => {
                        this.newModal = false;
                        this.selFrom = false;
                        this.selFroom = false;
                        this.selRoom = false;
                        this.selTo = false;
                        this.newExtra = {};
                        this.newModalReservation = false;
                        this.fetch();
                });
                    }
                else {
                  return false
                }
            },
            updateReservation()
            {
                const sendd = {"id":this.sres.proddata.id,"guests":this.sres.guests,"serviceday": this.sres.proddata.serviceday,
                    "order":this.sres.data,"nr":this.sres.data.data.product,"ad":this.sres.proddata.adults,
                    "kd":this.sres.proddata.kids,"df":this.sres.datefrom,"dt":this.sres.dateto,"r":this.sres.data.data.product,"s":this.sres.data.data.status};

                    dataService.axiosPost("urent", sendd).then(results => {
                        if(results) {
                            this.responseEditModal = false;
                            this.sres = {};
                            this.changedroom = "";
                            this.fetch();
                        }
                    });

            },
            stateCheck() {
                dataService.axiosFetch("state/rentals").then(results => {
                    this.lastState = results[0].l;
                });
            },
            reservationDelete(r) {
                dataService.axiosDelete('rental', r).then(results => {
                    if (results) {
                        this.newModal = false;
                        this.fetch();
                    }
                });
            },
            tableFixHead () {
                var topPos = document.documentElement.scrollTop;
                if(topPos-180 > 0)
                    this.scrollfromtop = topPos-175;
                else
                    this.scrollfromtop = 0;
            },
            fetch() {
                var fromt =moment(this.dateranger[0]).format("YYYY-MM-DD");
                var tot = moment(this.dateranger[1]).format("YYYY-MM-DD");

                this.periode = getDateArray(new Date(fromt), new Date(tot));
                this.loading = true;
                dataService.axiosFetch("rentals/" + fromt + '.' + tot).then(results => {
                    this.reservations = results;
                    //this.stateCheck();
                    this.loading = false;
                });

            },
        },

        directives: {
            infocus: {
                isLiteral: true,
                inserted: (el, binding) => {
                    let f = () => {
                        let rect = el.getBoundingClientRect();
                        let inView = (
                            rect.width > 0 &&
                            rect.height > 0 &&
                            rect.top >= 0 &&
                            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
                        );
                        if (inView) {
                            el.classList.add(binding.value)
                            //   window.removeEventListener('scroll', f)
                        } else {
                            el.classList.remove(binding.value)
                        }
                    };
                    window.addEventListener('scroll', f, { passive: true});
                    f()
                }
            }
        },
        mounted() {
            dataService.axiosFetch("bikes").then(results => {
             //   this.reservations = results;
                this.arooms = results.arooms;
                this.rt = results.rt;
                var roleops = [];
                results.rooms.forEach(obj => {
                    roleops.push({"value" : parseInt(obj.id) , "label" : obj.title});
                });
 
                this.models = roleops;


                   roleops = [];
                results.statuses.forEach(obj => {
                    roleops.push({"value" : obj.slug , "label" : obj.title});
                });


this.statuses = roleops;
var pa = this;
                roleops = [];
                results.rooms.forEach(obj => {
                    pa.bikeModels[parseInt(obj.id)] = obj.title;
                }); 
                this.cityData = results.rdt; 
                this.loading = false;
            });


            //    window.addEventListener("scroll", this.tableFixHead, { passive: true})

 
            this.loading = false;
            this.dateranger = [this.moment(new Date(), this.dateFormat).subtract(3, 'days'), this.moment(new Date(), this.dateFormat).add(26, 'days')];

            this.fetch();

            if(!this.interval)
            {this.interval = window.setInterval(() => {
 
            this.stateCheck()
            }, 6000);
            }
            dataService.axiosFetch("getcheckin").then(results => {
                this.guestsCheckin = results;
            });
            var fromt =moment(this.dateranger[0]).format("YYYY-MM-DD");
            var tot = moment(this.dateranger[1]).format("YYYY-MM-DD");

            this.periode = getDateArray(new Date(fromt), new Date(tot));

        },
        updated: function () {
            this.$nextTick(function () {


            });

        },
        watch: {
            lastState: function(val) {
                //do something when the data changes.
                if (val) {
                    this.fetch();
                }
            }
        },  created() {

        },
        activated() {

        },
        deactivated() {

        },
        beforeDestroy() {
            clearInterval( this.interval )
        },
    };
</script>

<style>




    .rentaldeskthis #blankspots {
        overflow: hidden
    }

    .rentaldeskthis .overflowy {
        max-height: 290px;
        overflow-y: auto;
        overflow-x: hidden
    }

    .rentaldeskthis .data-info {
        display: inline-block
    }


    .rentaldeskthis .ant-dropdown-menu-item.nopadding {
        padding: 0
    }

    .rentaldeskthis .ant-dropdown-menu-item span {
        display: block;
        width: 100%;
        height: 100%;
        padding: 5px 12px;
    }

    .rentaldeskthis #theader {
        position: fixed;
        z-index: 999;
        top: 45px;
        opacity: 0;
        white-space: nowrap;
        width: 1000%;
    }

    .rentaldeskthis .ant-input-search i {
        color: #F77E0B;
    }

    .rentaldeskthis .slim td {
        height: 50px;
        padding: 0;
        vertical-align: middle
    }

    .rentaldeskthis #theader th {
        display: block;
        float: left;
        width: 43px;
    }

    .rentaldeskthis .service-product {
        display: inline-block;
        width: 100%;
        clear: both;
    }
.text-info-order
{float: right;
    width: 60%;
    margin-right: 5%;
    color: #838383;
    line-height: 23px;}
    .rentaldeskthis .service-product b {
        float: right
    }

    .rentaldeskthis .blankspots {
        position: absolute
    }

    .rentaldeskthis .reservationshot {
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        position: absolute;
        top: 70px;
    }

    .rentaldeskthis .more-info .texttab {
        padding: 6px;
    }

    .rentaldeskthis .ordertab label,
    .rentaldeskthis .more-info .texttab label {
        font-size: 12px;
        width: 27%;
    }

    .rentaldeskthis .ordertab label {
        margin: 0;
        width: 20%;
    }

    .rentaldeskthis .modal-dialog {
        max-width: 50%;
    }

    .rentaldeskthis .desk .ant-select-selection--single {
        border-color: #dcdcdc;
    }

    .rentaldeskthis .form-control-commpact {
        float: right;
        width: 60%;
        margin-right: 5%;
        height: 32px;
        line-height:32px;
        border-radius: 4px;
        border: 1px solid #dcdcdc;
        border-top-width: 1.02px;
        overflow: visible;
    }



    .rentaldeskthis .form-control-commpact.ant-select,
    .rentaldeskthis .form-control-commpact.ant-calendar-picker {
        border: 0;
    }

    .rentaldeskthis .ordertab {
        font-size: 12px;
        min-width: 600px;
        line-height: 22px;
    }

    .rentaldeskthis .reservation {
        display: block;
        overflow: hidden;
        font-size: 11px;
        position: absolute;
        height: 58px;
        min-width:25px;
        top: 7px;
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #d4d4d4;
    }

    .rentaldeskthis .auto-x-overflow {
        overflow-y: hidden;
        overflow-x: auto
    }

    .rentaldeskthis .notassigned {
        position: absolute;
        display: none;
        top: 0;
        right: 0;
    }

    .rentaldeskthis .notassigned_reservation {
        display: block;
        overflow: hidden;
        font-size: 11px;
        height: 58px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 4px 9px -9px #000;
        border: 1px solid #d4d4d4;
    }

    .rentaldeskthis .inspace.betweenselected {
        background-color: #1cbbff;
    }

    .rentaldeskthis .inspace.selected,
    .rentaldeskthis .inspace.betweenselected.andlast {
        background-color: #2fe4ff;
    }

    .rentaldeskthis .more-info {
        font-size: 14px;
    }

    .rentaldeskthis .more-info label {
        min-width: 25%;
        color: #666;
        font-weight: bold
    }

    .rentaldeskthis .status_incart {
        background-color: rgb(221, 235, 249);

        border-style: dashed;
    }
    .rentaldeskthis b.status_incart
    {display:none;}
    .product-image
    {
           max-height: 65px;
    width: auto;
        padding:0px;
        border:7px solid #fff;
    }

    .new-reservation .form-group
    {width:49%!important;
    margin:.5%;}

.product-title
{
    font-size: 12px;
    display: inline-block;
    width: 55%;
    padding-left: 10px;
    padding-top:7px;
    line-height:14px;
    height: 100%;
    vertical-align: middle;
}
    .rentaldeskthis .reservation span {
        width: 100%;
        height: 100%;
        display: block;
        padding: 7px 12px;
        position:relative;
        z-index:2;
        white-space: nowrap;
    }

    .rentaldeskthis .gnam {
        display: inline-grid;
        margin-left: 4px;
    }

    .rentaldeskthis .reservation:hover {
        cursor: pointer;
        box-shadow: 5px 5px 5px -3px #ddd;
    }

    .rentaldeskthis .dayday {
        color: #182B4D;
        background-color: #fff;
        min-width: 50px;
        text-align: center;
        font-weight: 100;
        font-size: 20px;
    }

    .rentaldeskthis .dayday .dayday {
        background-color: transparent
    }
.rentaldeskthis .note-content {
    width: 96%;
}
    .rentaldeskthis .room ul {
        padding: 0;
        margin: 0;
        float: left;
    }

    .rentaldeskthis .room ul li {
        list-style-type: none;
        float: left;
        margin-right: 5px;
    }

    /* the classes */
    .rentaldeskthis .hidden {
        opacity: 0;
    }

    .rentaldeskthis .hidden-right {
        transform: translate(50px, 0);
    }

    .rentaldeskthis .note {
        display: block;
        clear: both;
        width: 100%;
        margin-top: 30px;
    }

    .rentaldeskthis .hidden-left {
        transform: translate(-50px, 0);
    }

    .rentaldeskthis .table.slim th {
        white-space: break-spaces
    }

    .rentaldeskthis .card .table.slim td,
    .rentaldeskthis .card .table.slim th {
        padding-left: .5rem;
        padding-right: .5rem;
        text-align: center;
    }

    .crossed,.crossed b
    {
        text-decoration: line-through;opacity:.4}
.rentaldeskthis .serviceday_true:after
{    display: block;
    content: ' +1 ';
    font-size: 11px;
    position: absolute;
    color: #fff;
    text-align: right;
    padding: 2px;
    height: 58px;
    width: 51px;
    z-index:0;
    right: 0;
    top: 0px;
    background-color: rgba(255, 255, 255, 0.08);
    line-height: 90px;}
    .rentaldeskthis .right-checkin {
        right: 0
    }
    .ant-select-dropdown-menu-item-selected, .ant-select-dropdown-menu-item-selected:hover
    {background-color: #d6e6ee;}
    .rentaldeskthis .pie-legend .card-body {
        padding: .735rem 1.5rem;
    }

    .rentaldeskthis .table.slim tr:nth-child(odd) td {

        background-color: #eff7fe;
    }
  
    .rentaldeskthis .bolder {
        font-weight: bold
    }

    .rentaldeskthis .fa-arrow-up,.rentaldeskthis  .fa-arrow-down {
        margin-right: 5px
    }

    .rentaldeskthis .showElement {
        opacity: 1;
        transform: translate(0, 0);
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
    }

    .rentaldeskthis .d_5,.rentaldeskthis  .d_6 {
        background-color: #f4fafe;
    }

    .rentaldeskthis .blank.d_5,
    .rentaldeskthis .blank.d_6 {
        background-color: #f4fafe;
    }

    .rentaldeskthis .datename {
        color: rgba(24, 43, 77, 0.61);
    }

    .rentaldeskthis .d_5 .dayday,.rentaldeskthis  .d_6 .dayday,
    .rentaldeskthis .d_5 .datename,.rentaldeskthis  .d_6 .datename {
        font-weight: bold;
    }

    .rentaldeskthis .froom td.isToday {
        background-color: #e3fef0;
    }
th.other
{border-top: 2px dashed #e3f7fe;}
    .rentaldeskthis .isToday {
        background-color: #e3fef0;
    }

    .rentaldeskthis .vue-slider-component .vue-slider-tooltip {
        border: 1px solid #0c3877;
        background-color: #0d336e;
    }

    .rentaldeskthis .vue-slider-component .vue-slider-process {
        background-color: #0d336e;
    }

    .rentaldeskthis .reservationshot td {
        height: 72px;
    }

    .rentaldeskthis .arrowscrollerers {
        position: fixed;
        border-radius: 4px;
        right: 0;
        top: 75px;
        width: 100px;
        z-index: 99999999;
    }

    .rentaldeskthis .arrowscroller {
        width: 58px;
        text-align: center;
        border-radius: 4px;
        margin: 3px;
        border: 1px solid #c4c4c4;
        line-height: 27px;
        background-color: #fff;
        float: left;
        -webkit-box-shadow: 2px 1px 13px -7px;
        box-shadow: 2px 1px 13px -7px;
        padding-top: 5px;
    }

    .rentaldeskthis .arrowscroller:hover {
        cursor: pointer;
        background-color: #f1f1f1
    }

    .rentaldeskthis .status_0 {
        background-color: #fff;
    }

    /*.tableFixHead
        {margin-top:70px;}
    .tableFixHead .rooms
    { padding:0;
          position: absolute;
        z-index: 9;
        background-color:#fff;}*/
    .rentaldeskthis .status_BOK {
        background-color: #4E93F2;
        color: #fff;
    }
 
    .rentaldeskthis .rangeselector {
        width: 250px;
    }

    .rentaldeskthis .status_payment_recieved {
        background-color: #b8edd8;
        color: #007a4c;
    }

    .rentaldeskthis .texttab .form-group {
        margin-bottom: 0.5rem;
        display:inline-block;
        clear:both;
        width:100%;
    }
    .rentaldeskthis .texttab .form-group label{
        line-height:45px;
        margin-bottom:0;
    }

    .rentaldeskthis .orderedit .form-group label {
        line-height: 30px;
    }

    .rentaldeskthis .orderedit .form-control-commpact {
        float: right;
        width: 60%;
        margin-right: 5%;
        height: 35px!important;
        line-height:35px;
        border-radius: 4px;
        border: 1px solid #dcdcdc;
        border-top-width: 1.02px;
        overflow: visible;
    }

    .rentaldeskthis .status_pending,
    .rentaldeskthis .status_2 {
        background-color: #6cc4f2;
        color: #fff;
    }

    .rentaldeskthis .status_storno {

        background-color: #feb4a7;
        color: #fff;
    }

    .rentaldeskthis .status_abandoned {
        background-color: #fe8f01;
        color: #fff;
    }

    .rentaldeskthis .status_completed,.rentaldeskthis  .status_1,.rentaldeskthis  .status_WEB {
        background-color: #4FCE8B;
        color: #fff;
    }




    .rentaldeskthis .ant-dropdown {
        min-width: 150px !important;
        z-index: 999999;
    }



    .rentaldeskthis .reservation {
        margin-left: -38px;
    }

    .rentaldeskthis .status_closed {
        background-color: rgba(255, 255, 255, 0.64);
        border-color: #fff;
        -webkit-box-shadow: 0 0;
        box-shadow: 0 0;
        margin-left: -30px;
        color: #c8c8c8;
        border-radius: 0;
    }

    .rentaldeskthis .status_closed:hover {
        box-shadow: 0 0;
        background-color: rgba(206, 206, 206, 0.25);
    }

    .rentaldeskthis .desk table {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .rentaldeskthis .rentaldesk th {
        height: 58px;
        font-size: 12px;
    }

    .rentaldeskthis .px-view {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .rentaldeskthis .blank {
        border: 1px solid #e1e1e1;
        height: 58px;
        width: 52px;
        min-width: 52px !important;
    }

    .rentaldeskthis .inspace {
        display: table;
        width: 100%;
        height: 100%;
    }


    .rentaldeskthis .blank:hover {
        background-color: #fefefe;
        cursor: pointer;
    }

    .rentaldeskthis .modal-body {
        margin: 0;
        background-color: #fefefe;
        max-height:70vh;
        overflow:auto;
    }

    .rentaldeskthis .edit-modal .ant-tabs .ant-tabs-left-bar {
        min-height: 410px;
        width: 120px;
    }

    .rentaldeskthis .edit-modal .ant-tabs-left-content .ant-tabs-bar {
        margin-top: -15px
    }

    .rentaldeskthis .detail-modal .ant-tabs .ant-tabs-left-bar {
        min-height: 400px;
        width: 120px;
    }

    .rentaldeskthis .modal-footer,
    .rentaldeskthis .modal-header {
        color: #fff;
        border-width: 2px;
        padding: 5px;
    }

    .rentaldeskthis .modal-header h4 {
        color: #787d9e;
        width: 100%;
        padding: 5px;
        text-align: left;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 900;
        margin: 0;
    }

    .rentaldeskthis .modal-header .subtext {
        color: #bebebe;
        padding: 5px;
    }


    .rentaldeskthis .card-header {
        padding: .5rem .5rem;
    }

    .rentaldeskthis .ant-btn {
        margin: 0 2px;
        padding: 0;
        min-height: 50px;
        min-width: 50px;
    }

    .rentaldeskthis .leftsider {
        background-color: #fff;
    }

    .rentaldeskthis .leftsider thead tr th,.rentaldeskthis  .leftsider thead tr td {
        height: 70px;
        background-color: #fff;
        border-bottom: 1px solid #7BB8F34D;
    }

    .rentaldeskthis .leftsider tr th {
        border-bottom: 1px solid #eff6fe;
        height: 72px;
        min-width: 150px;
    }
.rentaldeskthis hr
{margin-top: 1.2rem;
    margin-bottom: 1.2rem;}
    .rentaldeskthis .room-type-rect {
        display: block;
        margin: 7px 10px 7px 20px;
        width: 10px;
        height: 10px;
        background-color: #d31e40;
        float: left;
        border-radius: 2px;
    } 
    .rentaldeskthis .top-panel .ant-input {
        max-width: 220px;
        margin: 0 8px;
        min-height: 40px
    }

    .rentaldeskthis .ant-calendar-range-picker-separator {
        margin-top: 5px
    }

    .rentaldeskthis .bg-white {
        border: 0;
    }

    .rentaldeskthis .ttxt,.rentaldeskthis  .tlxt {
        float: left;
    }

    .rentaldeskthis .ttxt {
        width: 270px;
        position: absolute;
        z-index: 9
    }

    .rentaldeskthis .tlxt {
        position: relative;
        margin-left: 270px;
        width:100%;
    }

    .rentaldeskthis .datename {
        text-align: center;
    }

    .rentaldeskthis .bg-blueish {
        background-color: #8ad6e6;
    }

    .rentaldeskthis .tlxt th {
        height: 70px;
    }

    .rentaldeskthis .room-label {
        background-color: #fff;
        padding-left: 20px;
        font-weight: 100;
        height: 70px;
        border-bottom:1px solid #eff6fe;

    }
.rentaldeskthis .reservation b
{
border-radius: 4px;
    position:absolute;
    left:0;
    top:0;
    height:100%;
    width:8px;
}

    .rentaldeskthis .reservation b.status_pending
    {
        background: transparent linear-gradient(180deg, #6dadd9 0%, #88d6ff 100%) 0% 0% no-repeat padding-box;
    }
    .rentaldeskthis .header.diagonal:after {
        content: " ";
        display: inline-block;
        height: 100px;
        width: 100%;
        background-color: #f8f9fe;
        position: absolute;
        bottom: -50px;
        transform: skew(0deg, -3deg);
        box-shadow: 11px -12px 12px -15px #9c9c9c;
    }

    .rentaldeskthis .froom td {
        background-color: #fff;
        border: 1px solid #7BB8F34D;
        height: 72px;
    }

</style>
